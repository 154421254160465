export const en = {
  TOOLNAME: 'E-BOOKING TOOL',
  BRANDS: {
    versace: 'Versace',
    'michael-kors': 'Michael Kors',
    'jimmy-choo': 'Jimmy Choo',
  },
  ROLE: {
    ADMIN: 'Admin',
    OPERATOR: 'Operator',
    EMPLOYEE: 'Employee',
    GUEST: 'Guest',
    EXTERNAL_USER: 'Guest',
  },
  NAVBAR: {
    item_LOGIN_EMPLOYEE: 'LOGIN',
    item_PRIVACY_POLICY: 'PRIVACY POLICY',
    item_USERS: 'USER MANAGEMENT',
    item_EVENTS: 'EVENT MANAGEMENT',
    item_INVITATIONS: 'EVENTS LIST',
    item_BOOKINGS: 'MY APPOINTMENTS',
    item_LOGOUT: 'Logout',
  },
  FOOTER: {
    PRIVACY_POLICY: 'PRIVACY POLICY',
  },
  PAGE_NOT_FOUND: {
    PAGE_NOT_FOUND: 'Page not found',
    PAGE_NOT_FOUND_MESSAGE:
      'Sorry, we couldn’t find the page you are looking for',
  },
  SPLASH_PAGE: {
    E_PLANNING_TOOL: '$t(TOOLNAME)',
  },
  MOBILE_PAGE: {
    TITLE_MESSAGE: 'This website is not available on mobile devices',
    TEXT_MESSAGE: 'Please use your laptop to navigate this website.',
  },
  EVENT_ENDED: {
    TITLE: 'terminated event',
    MESSAGE: 'We are sorry but the event you are looking for has ended.',
  },
  LOGIN_EXTERNAL: {
    E_PLANNING_TOOL: '$t(TOOLNAME)',
    LOGIN: 'LOGIN',
    TEXT: 'To log in, use the e-mail address on which you received the invitation to the event and as password enter the one we sent you',
    CONTACT: 'Contact Support',
    PASSWORD: 'Password',
    EMAIL_ADDRESS: 'E-Mail Address',
    ERROR:
      'Invalid credentials, please check that you have entered them correctly',
    LOGIN_EMPLOYEE: 'Login as Employee',
  },
  LOGIN_EMPLOYEE: {
    E_PLANNING_TOOL: '$t(TOOLNAME)',
    LOGIN: 'LOGIN',
    BUTTON_TEXT: 'Login with sso',
    CONTACT: 'Contact Support',
    LOGIN_GUEST: 'Login as a guest',
    ERROR: 'Login failed',
  },
  USER_MANAGEMENT: {
    PAGE_TITLE: 'USER MANAGEMENT',
    BRAND_TITLE: 'Brand',
    TABLE_FILTER_LABEL: 'Search user by e-mail address or contact name',
    CONTACTNAME: 'Contact Name',
    EMAIL: 'E-Mail Address',
    ROLE: 'Role',
    ROW_PER_PAGE: 'Row per page:',
    DIPLAYED_ROWS: '{{from}}–{{to}} of {{count}}',
  },
  EXTERNAL_USER_PHONE_NUMBER_MODAL: {
    TITLE: 'Let’s keep in contact',
    TEXT: 'We need your mobile phone number in case we need to contact you.',
    PHONE_NUMBER: 'Phone number *',
    VALIDATION_TEXT_FIRST_PIECE: 'I have read and understand the ',
    VALIDATION_TEXT_SECOND_PIECE: 'Privacy and Cookie Policy',
    SEND_NUMBER: 'Send Number',
    RESPONSE_ERROR_MESSAGE: 'Something went wrong...',
  },
  MODAL_CLOSING_PAGE: {
    TITLE: 'Are you sure you want to close this page?',
    TEXT: 'All your data cannot be recovered and the action cannot be undone.',
    BUTTON_PRIMARY: 'Cancel',
    BUTTON_SECONDARY: 'Close Page',
  },
  EVENT_DOORMAN: {
    TITLE: 'Doorman checklist',
    SUB_TITLE:
      'In this page you can check the presence of booked people for a specific slot',
    NOTE: 'Message:',
    CLOSE: 'close',
    SEARCH: 'Search',
    NOT_FOUND: 'No result found',
    SHOW_ONLY_PRESENT: 'Show already checked people only',
    DOWNLOAD_CHECKLIST: 'Download the checklist',
    LOAD_MORE: 'Load more',
  },
  EVENT_LIST: {
    BUTTON_TEXT: 'Invite Guests',
  },
  EVENT_MODAL: {
    NOTE: 'Note',
    EDIT: 'Edit',
    DELETE: 'Delete',
  },
  EVENT_PEOPLE_BOOKED: {
    TITLE: 'Booked people details',
    SUB_TITLE:
      'In this page you can view booked people details for a specific slot.',
    NOTE: 'Message:',
    CLOSE: 'close',
    BOOKED_BY: 'Booked by: ',
  },
  EVENT_BEHALF_OF_BOOKING: {
    TITLE: 'Booking on behalf of others',
    SUB_TITLE:
      'It is possible to make bookings for the event time slot on behalf of others, providing their first name, last name, and (optionally) email.',
  },
  DATE_MOMENT: {
    HOURS_FORMAT: 'hh:mm A',
    DAY_STRING_FORMAT: 'dddd',
    NUMBER_DAY_FORMAT: 'DD',
    MONTH_FORMAT: 'MMMM',
    YEAR_FORMAT: 'YYYY',
    FULL_DATE: '$t(DATE_MOMENT.DAY_STRING_FORMAT) DD, MMMM YYYY',
    DATE_FORMAT: 'YYYY-MM-DD',
    DATE_ORDINARY_FORMAT: 'DD MMMM YYYY',
  },
  INVITE_GUEST: {
    SUB_TITLE:
      'You can invite a maximum of {{guests}} guest to the event. To invite a guest you need to complete his/her first name, ' +
      'last name and e-mail address, an email with the invitation link will be sent to your guest. You can not delete a guest you invited. ' +
      'Fields with * are required. Any guests who have already been invited by other users will not be counted among your potential invitees.',
    TEXT_BUTTON_ONE: 'Cancel',
    TEXT_BUTTON_TWO: 'Invite Guests',
    TITLE: 'Invite Guests',
    GUEST: 'Guest',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    EMAIL_ADDRESS: 'Guest Email',
    PHONE_NUMBER: 'Guest Phone Number',
    RESPONSE_SUCCESS: 'Guests invited successfully',
    RESPONSE_ERROR:
      'There was a problem with your guest invitations, please try again',
  },
  BOOKING_MODAL: {
    SUB_TITLE:
      'Please add a message if you wish, then click the “book appointment” button to submit your reservation.',
    TEXT_BUTTON_ONE: 'Cancel',
    TEXT_BUTTON_TWO: 'book appointment',
    TITLE: 'BOOK your APPOINTMENT',
    MESSAGE: 'Leave a message',
    ERROR: 'There was a problem with your booking, please try again',
    SUCCESS: 'Booking successfully made!',
    ACCESS_EVENT: 'Choose how to access the event *',
    IN_PERSON: 'In person',
    REMOTE: 'Online',
    RESPONSE_SUCCESS: 'Appointment edited successfully',
    RESPONSE_ERROR:
      'There was a problem editing your appointment, please try again',
    DELETE_RESPONSE_SUCCESS: 'Appointment deleted successfully',
    DELETE_RESPONSE_ERROR:
      'There was a problem deleting your appointment, please try again',
    BOOKING_AS: 'You are booking for the event as: ',
    PHONE_NUMBER: 'Phone number',
    GUESTS: {
      DESCRIPTION:
        'You can add other people as guests to your booking. Max slots available: {{maxGuests}}',
      REMOVE_BUTTON: 'Remove',
      ADD_BUTTON: 'Add a guest',
    },
  },
  EVENT_MANAGEMENT: {
    TITLE: 'Event management',
    BUTTON_DOWNLOAD_BOOKINGS: 'DOWNLOAD BOOKINGS',
    BUTTON_TEXT: 'Create Event',
    EDIT_BUTTON: 'Edit Event',
    DELETE_BUTTON: 'Delete Event',
    NO_TITLE: 'THERE ARE NO EVENTS',
    NO_MESSAGE: '',
    LINK_COPIED: 'Link copied to clipboard.',
  },
  EVENT_SLOT_DETAIL: {
    PEOPLE_BOOKED: '{{bookedSpots}}/{{slotLimit}} people booked',
    VIEW_BOOKED_PEOPLE: 'View booked people details',
    DOORMAN: 'View doorman checklist',
    BEHALF_OF_BOOKING: 'Book on behalf of',
    DISABLE_SLOT: 'Disable slot',
    ENABLE_SLOT: 'Enable Slot',
    BOOK_SLOT: 'Book appointment',
  },
  EVENT_SLOT_DELETE: {
    MODAL_TITLE: 'Do you want delete the slot?',
    MODAL_TEXT: 'There are some bookings ({{bookedSpots}}) for this slot.',
    MODAL_WARNING: 'All the bookings made for this slot will be deleted.',
    DELETE_RESPONSE_ERROR:
      'There was a problem deleting the slot, please try again',
    DELETE_RESPONSE_SUCCESS: 'Slot deleted successfully',
    BUTTON_CANCEL: 'cancel',
    BUTTON_DELETE: 'Delete the slot',
  },
  EVENT_SLOT_EDIT: {
    DISABLE: {
      MODAL_TITLE: 'Disable the slot',
      MODAL_TEXT:
        'By disabling the slot, users will no longer be able to make bookings for that time slot, while existing bookings will remain active',
      BUTTON_CONFIRM: 'Disabilita slot',
    },
    ENABLE: {
      MODAL_TITLE: 'Enable the slot',
      MODAL_TEXT:
        'To enable the slot, it is necessary to set the limit of people who can make bookings.',
      BUTTON_CONFIRM: 'Enable slot',
      SLOT_SIZE: 'Slot limit',
    },
    RESPONSE_ERROR: 'There was a problem editing the slot, please try again',
    RESPONSE_SUCCESS: 'Slot edited successfully',
    BUTTON_CANCEL: 'cancel',
  },
  EVENT_TYPE_TO_CREATE: {
    TITLE: 'Create event',
    TEXT: 'Select the location of the event and the type of event you want to create',
    LOCATION_TITLE: 'Event location *',
    CATEGORY_TITLE: 'Type of event *',
    BUTTON_ONE_TEXT: 'Cancel',
    BUTTON_TWO_TEXT: 'Customize event',
  },
  EVENT_CREATE_EDIT: {
    CREATE_TITLE: 'Create event: {{location}} - {{category}}',
    CREATE_SUB_TITLE:
      'When you click on “create event” button the event will be published. Only invited users can access to the booking. ' +
      "The mentioned times are based on the time zone of the event's location.",
    CREATE_SUB_TITLE_REQUIRED_FIELDS: 'Fields with * are required.',
    TEXT_BUTTON_ONE: 'Cancel',
    CREATE_TEXT_BUTTON_TWO: 'Create event',
    CREATE_RESPONSE_SUCCESS: 'Event created successfully',
    CREATE_RESPONSE_ERROR: 'A problem occurred, check the fields entered',
    EDIT_TITLE: 'Edit event: {{location}} - {{category}}',
    EDIT_SUB_TITLE:
      'When you click on the “save event” button the event will be published with the changes you have made. ' +
      'If you remove any of the dates, any appointments already booked on those dates will be cancelled and users will be notified.' +
      'Only invited users can access to the booking.',
    EDIT_SUB_TITLE_REQUIRED_FIELDS: 'Fields with * are required.',
    EDIT_TEXT_BUTTON_TWO: 'Save event',
    EDIT_RESPONSE_SUCCESS: 'Event updated successfully',
    EDIT_RESPONSE_ERROR: 'A problem occurred, check the fields entered',
    TOGGLE_VISIBILITY_RESPONSE_ERROR: 'A problem occurred, try again',
    GENERAL_INFO: {
      TITLE: 'General information',
      EVENT_NAME: 'Event name*',
      START_DATE: 'Start date*',
      END_DATE: 'End date*',
      GUESTS: 'Guest per employee*',
      SLOT_DURATION: 'Slot duration*',
      ALLOW_MULTIPLE_BOOKINGS: 'Allow multiple bookings',
      MIN_BOOKING_TIME: 'Minimum booking time (hours)',
    },
    WEEKLY_SCHEDULE: {
      TITLE: 'Weekly schedules',
      EDIT_SUBTITLE:
        "In edit, it's not possible to change the weekly slots time",
      WEEK_DAY_NUMBER: {
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
        7: 'Sunday',
      },
      CLOSED: 'Not available',
      START_TIME: 'Start time',
      END_TIME: 'End time',
      DELETE_SLOT: 'Delete slot',
    },
    SLOT_SIZE: {
      TITLE: 'Slots size management',
      SUB_TITLE: 'Slot dates must cover the entire duration of the event.',
      START_DATE: 'Start date*',
      END_DATE: 'End date*',
      SLOT_SIZE: 'Slot size*',
      BUTTON_ADD: 'Add slot',
      BUTTON_DELETE: 'Delete slot',
    },
    RESERVATION_OPENINGS: {
      TITLE: 'Employee and external users dates',
      VERSACE_LABEL: 'Versace employees*',
      VERSACE_START_DATE: 'Versace start date*',
      VERSACE_END_DATE: 'Versace end date*',
      CAPRI_LABEL: 'Capri employees',
      CAPRI_START_DATE: 'Capri start date',
      CAPRI_END_DATE: 'Capri end date',
      EXTERNAL_LABEL: 'External users*',
      EXTERNAL_START_DATE: 'External start date*',
      EXTERNAL_END_DATE: 'External end date*',
    },
    EXTRAORDINARY_SCHEDULE: {
      TITLE: 'Manage extraordinary',
      EDIT_SUBTITLE:
        "In edit, it's not possible to change the extraordinary time slots. Otherwise these changes, will not be applied when saving.",
      CLOSED: 'Not available',
      START_TIME: 'Start time',
      END_TIME: 'End time',
      DELETE_SLOT: 'Delete slot',
      ADD_DATE: 'Add date',
    },
  },
  EVENT_DELETE: {
    TITLE: 'Delete event',
    TEXT: 'Are you sure you want to delete the event',
    ADDITIONAL_TEXT:
      'Booked users will receive an email informing them that their appointment has been cancelled.',
    BUTTON_CANCEL: 'CANCEL',
    BUTTON_DELETE: 'DELETE EVENT',
    RESPONSE_SUCCESS: 'Event deleted successfully',
    RESPONSE_ERROR: 'There was a problem deleting your event, please try again',
  },
  EVENT_BOOKINGS_DOWNLOAD: {
    TITLE: 'Download the event bookings',
    SUB_TITLE:
      'On this page you can download the list of people booked for a specific time range.',
    ADDITIONAL_TEXT:
      'Select at least one of the dates to filter the bookings list.',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    TEXT_BUTTON_ONE: 'Close',
    TEXT_BUTTON_TWO: 'Download bookings',
  },
  APPOINTMENTS: {
    TITLE: 'MY APPOINTMENTS',
    NOTE: 'Note',
    EDIT_BUTTON: 'Edit',
    DELETE_BUTTON: 'Delete',
    NO_TITLE: 'you have no booked appointments yet',
    NO_MESSAGE:
      'To book an appointment, check your “event list” to see if you have been invited to an event, ' +
      'click on the event card to open its calendar, select a slot on the calendar and fill in the required information to book.',
    DELETE_MODAL_TITLE: 'DELETE YOUR APPOINTMENT',
    ADMIN_DELETE_MODAL_TITLE: 'DELETE THE APPOINTMENT',
    DELETE_MODAL_BUTTON_CANCEL: 'CANCEL',
    DELETE_MODAL_BUTTON_DELETE: 'DELETE APPOINTMENT',
    ADMIN_DELETE_MODAL_TEXT:
      'The user appointment will be deleted. This action can not be undone. Are you sure?',
    DELETE_MODAL_TEXT:
      'Your appointment will be deleted. This action can not be undone. Are you sure?',
    EDIT_MODAL_TITLE: 'EDIT your APPOINTMENT',
    EDIT_MODAL_SUBTITLE:
      'You can edit date and time of your appointment, you can also change the message you left. ' +
      'To submit your reservation click the “save edited appointment” button. Fields with * are required.',
    EDIT_MODAL_BUTTON_ONE_TEXT: 'Cancel',
    EDIT_MODAL_BUTTON_TWO_TEXT: 'SAVE EDITED appointment',
  },
  INVITATIONS: {
    TITLE: 'List of events you have been invited to',
    VIEW_CALENDAR: 'View Calendar',
    NO_TITLE: 'there are NO EVENTS YOU ARE INVITED TO YET',
    MIN_BOOKING_ALERT:
      'You must book at least {{hours}} hours prior to the event start time.',
    NO_MESSAGE:
      'If you think you have been invited to an event but it does not appear here, please check for the invitation E-Mail ' +
      'on your E-Mail client and open the invitation link.',
    APPOINTMENT_DATE: 'Appointment date *',
    APPOINTMENT_TIME: 'Appointment time *',
    APPOINTMENT_PRESENCE_TYPE: 'Presence Type *',
  },
  EVENT: {
    MALE: 'MAN',
    FEMALE: 'WOMAN',
    IN_PRESENCE: 'In Presence',
    REMOTE: 'Remote',
  },
  LOADING_MESSAGE: {
    TITLE: 'Loading',
    MESSAGE: 'Please wait...',
  },
  RESPONSE_ERROR_MESSAGE: {
    TITLE: 'Error',
    MESSAGE: 'Something went wrong...',
  },
  PRIVACY_POLICY: {
    TITLE: 'Privacy policy',
  },
  PRIVACY_POLICY_BANNER: {
    TEXT: 'This site uses technical session cookies to ensure correct use of navigation. For further details, please consult the Privacy Policy.',
    BUTTON_CONFIRM: 'OK, I got it',
  },
  CALENDAR: {
    ALREADY_BOOKED:
      'WARNING: You already have an active reservation. You must cancel your previous reservation before making a new reservation.',
  },
  TIMEZONE: {
    LOCATION: '(Event time)',
    LOCAL: '(Local time)',
    SWITCH_TIME_LOCATION: 'Show time of {{location}}',
    LOCATION_SHORT: '(event)',
    LOCAL_SHORT: '(local)',
  },
};
